<template>
  <div class="page">
    <Navbar title="基础资料" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          name="radio"
          label="性别"
          v-if="profile.PSN_BAS_GENDER !== undefined"
          :required="profile.PSN_BAS_GENDER === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_GENDER === 'MDR',
              message: '请选择性别',
            },
          ]"
        >
          <template #input>
            <van-radio-group
              v-model="base.gender"
              :checked-color="COLOR"
              direction="horizontal"
            >
              <van-radio
                v-for="item in genders"
                :key="item.code"
                :name="item.code"
                >{{ item.name }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="base.nationName"
          readonly
          clickable
          name="民族"
          label="民族"
          placeholder="点击选择民族"
          @click="nationShow = true"
          v-if="profile.PSN_BAS_NATION !== undefined"
          :required="profile.PSN_BAS_NATION === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_NATION === 'MDR',
              message: '请选择民族',
            },
          ]"
        />
        <van-popup :show="nationShow" position="bottom">
          <van-picker
            :columns="nations"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmNation"
            @cancel="nationShow = false"
          />
        </van-popup>
        <van-field
          name="marry"
          label="婚姻"
          v-if="profile.PSN_BAS_MARRY !== undefined"
          :required="profile.PSN_BAS_MARRY === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_MARRY === 'MDR',
              message: '请选择婚姻',
            },
          ]"
        >
          <template #input>
            <van-radio-group
              v-model="base.marryCode"
              :checked-color="COLOR"
              direction="horizontal"
            >
              <van-radio
                v-for="item in marrys"
                :key="item.code"
                :name="item.code"
                >{{ item.name }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="base.birthplaceName"
          readonly
          clickable
          name="birthplace"
          label="籍贯"
          placeholder="点击选择省市区"
          @click="birthplaceShow = true"
          v-if="profile.PSN_BAS_BIRTHPLACE !== undefined"
          :required="profile.PSN_BAS_BIRTHPLACE === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_BIRTHPLACE === 'MDR',
              message: '请选择籍贯',
            },
          ]"
        />
        <van-popup :show="birthplaceShow" position="bottom">
          <van-area
            :area-list="regions"
            @confirm="confirmBirthplace"
            @cancel="birthplaceShow = false"
          />
        </van-popup>
        <van-field
          v-model="base.regplaceName"
          readonly
          clickable
          name="regplace"
          label="户口所在地"
          placeholder="点击选择省市区"
          @click="regplaceShow = true"
          v-if="profile.PSN_BAS_REGPLACE !== undefined"
          :required="profile.PSN_BAS_REGPLACE === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_REGPLACE === 'MDR',
              message: '请选择户口所在地',
            },
          ]"
        />
        <van-popup :show="regplaceShow" position="bottom">
          <van-area
            :area-list="regions"
            @confirm="confirmRegplace"
            @cancel="regplaceShow = false"
          />
        </van-popup>
        <van-field
          v-model="base.passportCode"
          name="护照"
          label="护照"
          placeholder="请输入护照"
          v-if="profile.PSN_BAS_PASSPORT !== undefined"
          :required="profile.PSN_BAS_PASSPORT === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_PASSPORT === 'MDR',
              message: '请输入护照代码',
            },
          ]"
        />
        <van-field
          name="uploader"
          label="护照照片"
          v-if="profile.PSN_BAS_PASSPORT !== undefined"
          :required="profile.PSN_BAS_PASSPORT === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_PASSPORT === 'MDR',
              message: '请上传学位证照片',
            },
          ]"
        >
          <template #input>
            <van-uploader
              v-model="passportImages"
              :after-read="passportRead"
              :before-delete="imageDelete"
              :max-count="1"
            />
            <span class="tips fc-orange">请上传护照照片大小小于2M</span>
          </template>
        </van-field>
        <van-field
          v-model="base.regionName"
          readonly
          clickable
          name="region"
          label="居住地"
          placeholder="点击选择省市区"
          @click="regionShow = true"
          v-if="profile.PSN_BAS_REGION !== undefined"
          :required="profile.PSN_BAS_REGION === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_REGION === 'MDR',
              message: '请选择居住地',
            },
          ]"
        />
        <van-popup :show="regionShow" position="bottom">
          <van-area
            :area-list="regions"
            @confirm="confirmRegion"
            @cancel="regionShow = false"
          />
        </van-popup>
        <van-field
          v-model="base.address"
          name="详细地址"
          label="详细地址"
          placeholder="请输入详细地址"
          v-if="profile.PSN_BAS_ADDRESS !== undefined"
          :required="profile.PSN_BAS_ADDRESS === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_ADDRESS === 'MDR',
              message: '请填写地址',
            },
          ]"
        />
        <van-field
          v-model="base.stature"
          name="身高(cm)"
          label="身高(cm)"
          placeholder="请输入身高例如:175"
          v-if="profile.PSN_BAS_STATURE !== undefined"
          :required="profile.PSN_BAS_STATURE === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_STATURE === 'MDR',
              message: '请填写身高',
            },
          ]"
        />
        <van-field
          v-model="base.weight"
          name="体重(kg)"
          label="体重(kg)"
          placeholder="请输入身高例如:75"
          v-if="profile.PSN_BAS_WEIGHT !== undefined"
          :required="profile.PSN_BAS_WEIGHT === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_WEIGHT === 'MDR',
              message: '请填写体重',
            },
          ]"
        />
        <van-field
          v-model="base.sightLeft"
          name="左眼视力"
          label="左眼视力"
          placeholder="请输入身高例如:75"
          v-if="profile.PSN_BAS_SIGHT !== undefined"
          :required="profile.PSN_BAS_SIGHT === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_SIGHT === 'MDR',
              message: '请填写视力',
            },
          ]"
        />
        <van-field
          v-model="base.sightRight"
          name="右眼视力"
          label="右眼视力"
          placeholder="请输入身高例如:75"
          v-if="profile.PSN_BAS_SIGHT !== undefined"
          :required="profile.PSN_BAS_SIGHT === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_SIGHT === 'MDR',
              message: '请填写视力',
            },
          ]"
        />
        <van-field
          v-model="base.politicalName"
          readonly
          clickable
          name="政治面貌"
          label="政治面貌"
          placeholder="点击选择政治面貌"
          @click="politicalShow = true"
          v-if="profile.PSN_BAS_POLITICAL !== undefined"
          :required="profile.PSN_BAS_POLITICAL === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_POLITICAL === 'MDR',
              message: '请选择政治面貌',
            },
          ]"
        />
        <van-popup :show="politicalShow" position="bottom">
          <van-picker
            :columns="politicals"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmPolitical"
            @cancel="politicalShow = false"
          />
        </van-popup>
        <van-field
          v-model="base.qualifyName"
          readonly
          clickable
          name="最高学历"
          label="最高学历"
          placeholder="点击选择最高学历"
          @click="qualifyShow = true"
          v-if="profile.PSN_BAS_QUALIFY !== undefined"
          :required="profile.PSN_BAS_QUALIFY === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_QUALIFY === 'MDR',
              message: '请选择最高学历',
            },
          ]"
        />
        <van-popup :show="qualifyShow" position="bottom">
          <van-picker
            :columns="qualifys"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmQualify"
            @cancel="qualifyShow = false"
          />
        </van-popup>
        <van-field
          v-model="base.school"
          name="毕业学校"
          label="毕业学校"
          placeholder="请输入毕业学校"
          v-if="profile.PSN_BAS_SCHOOL !== undefined"
          :required="profile.PSN_BAS_SCHOOL === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_SCHOOL === 'MDR',
              message: '请填写毕业学校',
            },
          ]"
        />
        <van-field
          v-model="base.major"
          name="毕业专业"
          label="毕业专业"
          placeholder="请输入毕业专业"
          v-if="profile.PSN_BAS_MAJOR !== undefined"
          :required="profile.PSN_BAS_MAJOR === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_MAJOR === 'MDR',
              message: '请填写毕业专业',
            },
          ]"
        />
        <van-field
          v-model="base.gradtime"
          name="毕业时间"
          readonly
          clickable
          label="毕业时间"
          placeholder="请选择毕业时间"
          @click="gradtimeShow = true"
          v-if="profile.PSN_BAS_GRADTIME !== undefined"
          :required="profile.PSN_BAS_GRADTIME === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_GRADTIME === 'MDR',
              message: '请选择毕业时间',
            },
          ]"
        />
        <van-popup :show="gradtimeShow" position="bottom">
          <van-datetime-picker
            type="year-month"
            title="选择年月"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="gradtimeShow = false"
            @confirm="gradtimeConfirm"
            :formatter="formatter"
          />
        </van-popup>
        <van-field
          name="uploader"
          label="毕业证照片"
          v-if="profile.PSN_BAS_GRADCERT !== undefined"
          :required="profile.PSN_BAS_GRADCERT === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_GRADCERT === 'MDR',
              message: '请上传毕业证照片',
            },
          ]"
        >
          <template #input>
            <van-uploader
              v-model="gradcertImages"
              :after-read="gradcertRead"
              :before-delete="imageDelete"
              :max-count="1"
            />
            <span class="tips fc-orange">请上毕业证照片照片大小小于2M</span>
          </template>
        </van-field>
        <van-field
          name="uploader"
          label="学位证照片"
          v-if="profile.PSN_BAS_DEGREECERT !== undefined"
          :required="profile.PSN_BAS_DEGREECERT === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_DEGREECERT === 'MDR',
              message: '请上传学位证照片',
            },
          ]"
        >
          <template #input>
            <van-uploader
              v-model="degreecertImages"
              :after-read="degreecertRead"
              :before-delete="imageDelete"
              :max-count="1"
            />
            <span class="tips fc-orange">请上毕业证照片照片大小小于2M</span>
          </template>
        </van-field>
        <van-field
          name="uploader"
          label="学籍验证报告"
          v-if="profile.PSN_BAS_SVR !== undefined"
          :required="profile.PSN_BAS_SVR === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_SVR === 'MDR',
              message: '请上传学籍验证报告',
            },
          ]"
        >
          <template #input>
            <van-uploader
              v-model="svrDocuments"
              :after-read="svrRead"
              :before-delete="documentDelete"
              :max-count="1"
              upload-icon="plus"
            >
            </van-uploader>
            <span class="tips fc-orange"> 请上传学信网下载的学籍验证报告 </span>
          </template>
        </van-field>
        <van-field
          v-model="base.englishName"
          readonly
          clickable
          name="英语等级"
          label="英语等级"
          placeholder="点击选择英语等级"
          @click="englishShow = true"
          v-if="profile.PSN_BAS_ENGLISH !== undefined"
          :required="profile.PSN_BAS_ENGLISH === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_ENGLISH === 'MDR',
              message: '请选择英语等级',
            },
          ]"
        />
        <van-popup :show="englishShow" position="bottom">
          <van-picker
            :columns="englishs"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmEnglish"
            @cancel="englishShow = false"
          />
        </van-popup>
        <van-field
          v-model="base.duration"
          name="工作年限"
          label="工作年限"
          placeholder="请输入工作年限"
          v-if="profile.PSN_BAS_DURATION !== undefined"
          :required="profile.PSN_BAS_DURATION === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_DURATION === 'MDR',
              message: '请填写工作年限',
            },
          ]"
        />
        <van-field
          v-model="base.healthName"
          readonly
          clickable
          name="健康"
          label="健康"
          placeholder="点击选择政治面貌"
          @click="healthShow = true"
          v-if="profile.PSN_BAS_HEALTH !== undefined"
          :required="profile.PSN_BAS_HEALTH === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_HEALTH === 'MDR',
              message: '请选择身体状态',
            },
          ]"
        />
        <van-popup :show="healthShow" position="bottom">
          <van-picker
            :columns="healths"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmHealth"
            @cancel="healthShow = false"
          />
        </van-popup>
        <van-field
          v-model="base.tattooName"
          readonly
          clickable
          name="纹身"
          label="纹身"
          placeholder="点击选择纹身"
          @click="tattooShow = true"
          v-if="profile.PSN_BAS_TATTOO !== undefined"
          :required="profile.PSN_BAS_TATTOO === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_TATTOO === 'MDR',
              message: '请选择纹身类型',
            },
          ]"
        />
        <van-popup :show="tattooShow" position="bottom">
          <van-picker
            :columns="tattoos"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmTattoo"
            @cancel="tattooShow = false"
          />
        </van-popup>
        <van-field
          v-model="base.introduce"
          rows="2"
          autosize
          label="自我介绍"
          type="textarea"
          maxlength="300"
          placeholder="请输入自我介绍"
          show-word-limit
          v-if="profile.PSN_BAS_INTRODUCE !== undefined"
          :required="profile.PSN_BAS_INTRODUCE === 'MDR'"
          :rules="[
            {
              required: profile.PSN_BAS_INTRODUCE === 'MDR',
              message: '请填写自我介绍',
            },
          ]"
        />
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            :color="COLOR"
            size="small"
            icon="passed"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Form, Field, CellGroup, RadioGroup, Radio, Picker, Popup, Area, Steps, Step, DatetimePicker, Uploader } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader
  },
  data () {
    return {
      step: 2,
      personCode: '',
      profileKey: '',
      regions: {},
      qualifys: [{ code: '', name: '未选择' }],
      healths: [{ code: '', name: '未选择' }],
      tattoos: [{ code: '', name: '未选择' }],
      englishs: [{ code: '', name: '未选择' }],
      politicals: [{ code: '', name: '未选择' }],
      nations: [{ code: '', name: '未选择' }],
      genders: [{ code: '', name: '' }],
      marrys: [{ code: '', name: '未选择' }],
      passportImages: [],
      gradcertImages: [],
      degreecertImages: [],
      svrDocuments: [],
      profile: {},
      base: {
        gender: '',
        nationName: '',
        nationCode: '',
        marryCode: '',
        marryName: '',
        birthplaceCode: '',
        birthplaceName: '',
        regplaceCode: '',
        regplaceName: '',
        passportCode: '',
        passportImage: '',
        regionCode: '',
        regionName: '',
        address: '',
        stature: 0,
        weight: 0,
        sightLeft: 0,
        sightRight: 0,
        politicalName: '',
        politicalCode: '',
        qualifyName: '',
        qualifyCode: '',
        school: '',
        major: '',
        gradtime: '',
        gradcertCode: '',
        degreecertCode: '',
        svrCode: '',
        englishName: '',
        englishCode: '',
        duration: 1,
        healthName: '',
        healthCode: '',
        tattooName: '',
        tattooCode: '',
        introduce: ''
      },
      nationShow: false,
      politicalShow: false,
      qualifyShow: false,
      englishShow: false,
      regionShow: false,
      birthplaceShow: false,
      regplaceShow: false,
      healthShow: false,
      tattooShow: false,
      gradtimeShow: false,
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      sendBtnTime: 0,
      loadingShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    this.business = query.business
    this.profileKey = query.profile
    var code = query.personCode
    if (code !== '' && code !== undefined && code !== null) {
      this.personCode = code
      this.profile = JSON.parse(window.sessionStorage.getItem(window.const.global.PEOPLE_PROFILE_ATTR))
      this.retrieveAttr()
    }
    this.retrieveRegions()
    this.$refs.share.default('main')
  },
  methods: {
    async imageDelete (image) {
      var pd = { code: image.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/deleteImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (image.key === 'PEP_PSN_GRADCERT') {
          this.base.gradcertCode = ''
        } else if (image.key === 'PEP_PSN_DEGREECERT') {
          this.base.degreecertCode = ''
        }
        return true
      } else {
        return false
      }
    },
    async documentDelete (document) {
      var pd = { code: document.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/document/deleteDocument', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (document.key === 'PEP_PSN_SVR') {
          this.base.svrCode = ''
        }
        return true
      } else {
        return false
      }
    },
    passportRead (file) {
      file.key = 'PEP_PSN_PASSPORT'
      this.uploadImage(file, '护照照片')
    },
    gradcertRead (file) {
      file.key = 'PEP_PSN_GRADCERT'
      this.uploadImage(file, '毕业证照片')
    },
    degreecertRead (file) {
      file.key = 'PEP_PSN_DEGREECERT'
      this.uploadImage(file, '学位证照片')
    },
    svrRead (file) {
      file.key = 'PEP_PSN_SVR'
      this.uploadDocument(file, '学籍验证报告')
    },
    async uploadImage (file, alias) {
      var that = this
      this.loadingShow = true
      var pd = { dictKey: file.key, content: file.content, name: file.file.name, alias: alias, attach: 'degreecert' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/uploadImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        file.code = res.data.code
        if (file.key === 'PEP_PSN_GRADCERT') {
          that.base.gradcertCode = res.data.code
        } else if (file.key === 'PEP_PSN_DEGREECERT') {
          that.base.degreecertCode = res.data.code
        } else if (file.key === 'PEP_PSN_PASSPORT') {
          that.base.passportImage = res.data.code
        }
      }
      this.loadingShow = false
    },
    async uploadDocument (file, alias) {
      var that = this
      this.loadingShow = true
      var pd = { dictKey: 'PEP_PSN_SVR', content: file.content, name: file.file.name, alias: alias, attach: 'svr' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/document/uploadDocument', this.$qs.stringify(pd))
      if (res.status === '200') {
        file.code = res.data.code
        if (file.key === 'PEP_PSN_SVR') {
          that.base.svrCode = res.data.code
        }
      }
      this.loadingShow = false
    },
    confirmNation (val) {
      this.base.nationName = val.name
      this.base.nationCode = val.code
      this.nationShow = false
    },
    confirmMarry (val) {
      this.base.marryName = val.name
      this.base.marryCode = val.code
    },
    confirmQualify (value) {
      this.base.qualifyCode = value.code
      this.base.qualifyName = value.name
      this.qualifyShow = false
    },
    confirmPolitical (value) {
      this.base.politicalCode = value.code
      this.base.politicalName = value.name
      this.politicalShow = false
    },
    confirmHealth (value) {
      this.base.healthCode = value.code
      this.base.healthName = value.name
      this.healthShow = false
    },
    confirmTattoo (value) {
      this.base.tattooCode = value.code
      this.base.tattooName = value.name
      this.tattooShow = false
    },
    confirmRegion (values) {
      this.base.regionName = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      this.base.regionCode = values[2].code
      this.regionShow = false
    },
    confirmBirthplace (values) {
      this.base.birthplaceName = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      this.base.birthplaceCode = values[2].code
      this.birthplaceShow = false
    },
    confirmRegplace (values) {
      this.base.regplaceName = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      this.base.regplaceCode = values[2].code
      this.regplaceShow = false
    },
    confirmEnglish (value) {
      this.base.englishCode = value.code
      this.base.englishName = value.name
      this.englishShow = false
    },
    gradtimeConfirm (val) {
      var year = val.getFullYear()
      var month = val.getMonth() + 1
      this.base.gradtime = year + '年' + month + '月'
      this.gradtimeShow = false
    },
    async retrieveRegions () {
      var pd = { level: 3 }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveLevelRegion', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.regions.province_list = res.data.one
        this.regions.city_list = res.data.two
        this.regions.county_list = res.data.three
      }
    },
    formatter (type, val) {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    async submit () {
      this.loadingShow = true
      var attrs = [
        { key: 'PSN_BAS_GENDER', type: 'DTL', content: { code: this.base.gender }, seq: '1' },
        { key: 'PSN_BAS_NATION', type: 'DTL', content: { code: this.base.nationCode, name: this.base.nationName }, seq: '1' },
        { key: 'PSN_BAS_MARRY', type: 'DTL', content: { code: this.base.marryCode }, seq: '1' },
        { key: 'PSN_BAS_BIRTHPLACE', type: 'DTL', content: { code: this.base.birthplaceCode, name: this.base.birthplaceName }, seq: '1' },
        { key: 'PSN_BAS_REGPLACE', type: 'DTL', content: { code: this.base.regplaceCode, name: this.base.regplaceName }, seq: '1' },
        { key: 'PSN_BAS_PASSPORT', type: 'DTL', content: { code: this.base.passportCode, imageCode: this.base.passportImage }, seq: '1' },
        { key: 'PSN_BAS_REGION', type: 'DTL', content: { code: this.base.regionCode, name: this.base.regionName }, seq: '1' },
        { key: 'PSN_BAS_ADDRESS', type: 'DTL', content: { value: this.base.address }, seq: '1' },
        { key: 'PSN_BAS_STATURE', type: 'DTL', content: { value: this.base.stature }, seq: '1' },
        { key: 'PSN_BAS_WEIGHT', type: 'DTL', content: { value: this.base.weight }, seq: '1' },
        { key: 'PSN_BAS_SIGHT', type: 'DTL', content: { left: this.base.sightLeft, right: this.base.sightRight }, seq: '1' },
        { key: 'PSN_BAS_POLITICAL', type: 'DTL', content: { code: this.base.politicalCode, name: this.base.politicalName }, seq: '1' },
        { key: 'PSN_BAS_QUALIFY', type: 'DTL', content: { code: this.base.qualifyCode, name: this.base.qualifyName }, seq: '1' },
        { key: 'PSN_BAS_SCHOOL', type: 'DTL', content: { name: this.base.school }, seq: '1' },
        { key: 'PSN_BAS_MAJOR', type: 'DTL', content: { name: this.base.major }, seq: '1' },
        { key: 'PSN_BAS_GRADTIME', type: 'DTL', content: { value: this.base.gradtime }, seq: '1' },
        { key: 'PSN_BAS_GRADCERT', type: 'DTL', content: { code: this.base.gradcertCode }, seq: '1' },
        { key: 'PSN_BAS_DEGREECERT', type: 'DTL', content: { code: this.base.degreecertCode }, seq: '1' },
        { key: 'PSN_BAS_SVR', type: 'DTL', content: { code: this.base.svrCode }, seq: '1' },
        { key: 'PSN_BAS_ENGLISH', type: 'DTL', content: { code: this.base.englishCode, name: this.base.englishName }, seq: '1' },
        { key: 'PSN_BAS_DURATION', type: 'DTL', content: { value: this.base.duration }, seq: '1' },
        { key: 'PSN_BAS_HEALTH', type: 'DTL', content: { code: this.base.healthCode, name: this.base.healthName }, seq: '1' },
        { key: 'PSN_BAS_TATTOO', type: 'DTL', content: { code: this.base.tattooCode, name: this.base.tattooName }, seq: '1' },
        { key: 'PSN_BAS_INTRODUCE', type: 'DTL', content: { value: this.base.introduce }, seq: '1' }
      ]
      var pd = {
        personCode: this.personCode,
        attrs: JSON.stringify(attrs)
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '基础资料',
          message: '保存成功'
        }).then(() => {
          this.$router.go(-1)
        })
      } else {
        this.$dialog.alert({
          title: '基础资料',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async retrieveAttr () {
      var pd = { personCode: this.personCode, group: 'BAS', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_BAS_GENDER') {
            this.base.gender = obj.code
          } else if (element.key === 'PSN_BAS_NATION') {
            this.base.nationCode = obj.code
            this.base.nationName = obj.name
          } else if (element.key === 'PSN_BAS_MARRY') {
            this.base.marryCode = obj.code
            this.base.marryName = obj.imageCode
          } else if (element.key === 'PSN_BAS_BIRTHPLACE') {
            this.base.birthplaceCode = obj.code
            this.base.birthplaceName = obj.name
          } else if (element.key === 'PSN_BAS_REGPLACE') {
            this.base.regplaceCode = obj.code
            this.base.regplaceName = obj.name
          } else if (element.key === 'PSN_BAS_PASSPORT') {
            this.base.passportCode = obj.code
            this.base.passportImage = obj.imageCode
            this.retrieveImage(obj.imageCode, 'PEP_PSN_PASSPORT')
          } else if (element.key === 'PSN_BAS_REGION') {
            this.base.regionCode = obj.code
            this.base.regionName = obj.name
          } else if (element.key === 'PSN_BAS_ADDRESS') {
            this.base.address = obj.value
          } else if (element.key === 'PSN_BAS_STATURE') {
            this.base.stature = obj.value
          } else if (element.key === 'PSN_BAS_WEIGHT') {
            this.base.weight = obj.value
          } else if (element.key === 'PSN_BAS_SIGHT') {
            this.base.sightLeft = obj.left
            this.base.sightRight = obj.right
          } else if (element.key === 'PSN_BAS_POLITICAL') {
            this.base.politicalCode = obj.code
            this.base.politicalName = obj.name
          } else if (element.key === 'PSN_BAS_QUALIFY') {
            this.base.qualifyCode = obj.code
            this.base.qualifyName = obj.name
          } else if (element.key === 'PSN_BAS_SCHOOL') {
            this.base.school = obj.name
          } else if (element.key === 'PSN_BAS_MAJOR') {
            this.base.major = obj.name
          } else if (element.key === 'PSN_BAS_GRADTIME') {
            this.base.gradtime = obj.value
          } else if (element.key === 'PSN_BAS_GRADCERT') {
            var gradcertCode = obj.code
            if (gradcertCode !== undefined && gradcertCode !== '') {
              this.base.gradcertCode = gradcertCode
              this.retrieveImage(gradcertCode, 'PEP_PSN_GRADCERT')
            }
          } else if (element.key === 'PSN_BAS_DEGREECERT') {
            var degreecertCode = obj.code
            if (degreecertCode !== undefined && degreecertCode !== '') {
              this.base.degreecertCode = degreecertCode
              this.retrieveImage(degreecertCode, 'PEP_PSN_DEGREECERT')
            }
          } else if (element.key === 'PSN_BAS_SVR') {
            var svrCode = obj.code
            if (svrCode !== undefined && svrCode !== '') {
              this.base.svrCode = svrCode
              this.retrieveDocument(svrCode, 'PEP_PSN_SVR')
            }
          } else if (element.key === 'PSN_BAS_ENGLISH') {
            this.base.englishCode = obj.code
            this.base.englishName = obj.name
          } else if (element.key === 'PSN_BAS_DURATION') {
            this.base.duration = obj.value
          } else if (element.key === 'PSN_BAS_HEALTH') {
            this.base.healthCode = obj.code
            this.base.healthName = obj.name
          } else if (element.key === 'PSN_BAS_TATTOO') {
            this.base.tattooCode = obj.code
            this.base.tattooName = obj.name
          } else if (element.key === 'PSN_BAS_INTRODUCE') {
            this.base.introduce = obj.value
          }
        })
        this.retrieveNationOption()
        this.retrieveQualifyOption()
        this.retrieveHealthOption()
        this.retrieveTattooOption()
        this.retrieveEnglishOption()
        this.retrievePoliticalOption()
        this.retrieveGenderOption()
        this.retrieveMarryOption()
      }
    },
    async retrieveRegionName () {
      var pd = { code: this.state.regionCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveRegionName', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.base.regionName = res.data.one + '/' + res.data.two + '/' + res.data.three
      }
    },
    async retrieveNationOption () {
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/nation/retrieveNationSelect')
      if (res.status === '200') {
        this.nations = res.data
      }
    },
    async retrieveGenderOption () {
      var pd = { key: 'GENDER_TYPE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.genders = res.data.item
      }
    },
    async retrieveMarryOption () {
      var pd = { key: 'MARRY_STATE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.marrys = res.data.item
      }
    },
    async retrieveQualifyOption () {
      var pd = { key: 'QUALIFY_TYPE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.qualifys = res.data.item
      }
    },
    async retrieveHealthOption () {
      var pd = { key: 'HEALTH_TYPE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.healths = res.data.item
      }
    },
    async retrieveTattooOption () {
      var pd = { key: 'TATTOO_TYPE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.tattoos = res.data.item
      }
    },
    async retrieveEnglishOption () {
      var pd = { key: 'ENGLISH_TYPE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.englishs = this.englishs.concat(res.data.item)
      }
    },
    async retrievePoliticalOption () {
      var pd = { key: 'POLITICAL_TYPE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.politicals = res.data.item
      }
    },
    async retrieveImage (code, key) {
      var pd = { code: code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/retrieveImagePath', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (key === 'PEP_PSN_GRADCERT') {
          this.gradcertImages.push({ url: res.data.path, code: res.data.code, key: key })
        } else if (key === 'PEP_PSN_DEGREECERT') {
          this.degreecertImages.push({ url: res.data.path, code: res.data.code, key: key })
        } else if (key === 'PEP_PSN_PASSPORT') {
          this.passportImages.push({ url: res.data.path, code: res.data.code, key: key })
        }
      }
    },
    async retrieveDocument (code, key) {
      var pd = { code: code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/document/retrieveDocument', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (key === 'PEP_PSN_SVR') {
          this.svrDocuments.push({ url: res.data.oldName, code: code, key: key })
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.step {
  text-align: left;
}
.form {
  padding: 0px 5px;
  margin-bottom: 30px;
}
.title {
  font-size: 13px;
  padding: 8px 0px 0px 10px;
  font-weight: 600;
  text-align: left;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
